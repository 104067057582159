<template>
	<div class="manager-table-container table-form-field">
		<div class="copy-button">
			<el-button
				class="action-button info-button"
				size="mini"
				type="info"
				@click="copyToBuffer"
				>КОПИРОВАТЬ В БУФЕР
			</el-button>
		</div>
		<div :class="['manager-table', { copiedToBuffer: copiedToBuffer }]">
			<table>
				<tbody>
					<tr>
						<td v-for="(bufferItem, idx) in tableData" :key="`mgt_item-${idx}`">
							{{ bufferItem }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
// import { mapActions, mapState } from 'vuex';

export default {
	props: {
		tableData: {
			type: Array,
			default: () => []
		},
		tableSettings: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			copiedToBuffer: false
		};
	},
	computed: {},

	beforeDestroy() {
		this.copiedToBuffer = false;
	},

	methods: {
		copyToBuffer() {
			var string = this.tableData.join('\t');
			if (string) {
				var input = document.createElement('textarea');
				input.id = 'test';
				input.value = string;
				input.setAttribute('readonly', '');
				input.style.position = 'absolute';
				input.style.left = '-9999px';
				input.style.opacity = '0';
				input.style.zIndex = -1000;
				document.body.appendChild(input);
				input.focus();
				input.select();
				// console.log(document.getSelection())
				document.execCommand('copy');
				document.body.removeChild(input);
				this.copiedToBuffer = true;
			}
		}
	}
};
</script>
